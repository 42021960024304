import { MaintenanceTypeEnum } from "../../../types/gqlGeneratedPrivate";

export function textForMaintenanceType(type: string): string {
  switch (type) {
    case "regularMaintenance":
      return "Pravidelný servis";
    case "fault":
      return "Závada";
    default:
      return "Neznámý typ";
  }
}

export function maintenanceTypes(): Array<{ value: string; text: string }> {
  const maintenanceTypes = [];
  for (const type of Object.values(MaintenanceTypeEnum)) {
    maintenanceTypes.push({
      value: type,
      text: textForMaintenanceType(type),
    });
  }
  return maintenanceTypes;
}
