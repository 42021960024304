
















































































































import Vue from "vue";
import Component from "vue-class-component";
import TableHeader from "../../commons/tables/TableHeader.vue";
import ActionsWrapper from "../../commons/tables/ActionsWrapper.vue";
import RoundButton from "../../commons/inputsAndControls/RoundButton.vue";
import {
  TableColumnDataType,
  TableFilter,
  TableFilterItem,
  TableFilterPayload,
  TableOptions,
} from "../../commons/tables/types";
import {
  Device,
  FilterOperatorEnum,
  Laborer,
  Maintenance,
  MaintenancesFilterEnum,
  MaintenanceStateEnum,
} from "../../../types/gqlGeneratedPrivate";
import { TablesActions, TablesNamespaces } from "../../../store/modules/tables";
import { Routes } from "../../../enums";
import Table from "../../commons/tables/Table.vue";
import { Prop } from "vue-property-decorator";
import { toLocalDateTime } from "../../../filters/datetime";
import { Action } from "vuex-class";

import Button from "../../commons/inputsAndControls/Button.vue";
import { DateTime } from "luxon";
import TextField from "../../commons/inputsAndControls/TextField.vue";
import SelectBox from "../../commons/inputsAndControls/SelectBox.vue";
import DateField from "../../commons/inputsAndControls/DateField.vue";
import { apiClient } from "../../../utils";
import { api } from "../../../api";
import { maintenanceTypes, textForMaintenanceType } from "./maintenanceTypes";

@Component({
  components: {
    DateField,
    SelectBox,
    TextField,
    Button,
    Table,
    RoundButton,
    ActionsWrapper,
    TableHeader,
  },
  filters: {
    toLocalDateTime,
  },
})
export default class CompletedMaintenancesTable extends Vue {
  textForMaintenanceType = textForMaintenanceType;

  @Prop({ type: Number, required: false })
  deviceId!: number;

  @Action(TablesActions.Filter, {
    namespace: TablesNamespaces.MaintenancesTable,
  })
  filterTicketsTable!: (payload: TableFilterPayload) => Promise<void>;

  Routes = Routes;

  createFilter(
    namespace: string,
    operator: FilterOperatorEnum,
    value: Array<string | number>
  ): TableFilterItem {
    return {
      column: namespace,
      operator: operator,
      values: value.map((item) => item.toString()),
    };
  }

  prepareAdvancedFilter() {
    let customFilter: TableFilter = [];

    if (this.deviceNameFilter) {
      let filter = {
        filter: this.createFilter(
          MaintenancesFilterEnum.Device,
          FilterOperatorEnum.Equal,
          [this.deviceNameFilter]
        ),
      };
      customFilter.push(filter);
    }

    if (this.dateFilter) {
      let filter = {
        filter: this.createFilter(
          MaintenancesFilterEnum.Maintenancedate,
          FilterOperatorEnum.Between,
          [
            DateTime.fromISO(this.dateFilter, { zone: "utc" })
              .startOf("day")
              .toISO(),
            DateTime.fromISO(this.dateFilter, { zone: "utc" })
              .endOf("day")
              .toISO(),
          ]
        ),
      };
      customFilter.push(filter);
    }

    if (this.typeFilter) {
      let filter = {
        filter: this.createFilter(
          MaintenancesFilterEnum.Type,
          FilterOperatorEnum.Equal,
          [this.typeFilter]
        ),
      };
      customFilter.push(filter);
    }

    if (this.laborerFilter) {
      let filter = {
        filter: this.createFilter(
          MaintenancesFilterEnum.Responsibleperson,
          FilterOperatorEnum.Equal,
          [this.laborerFilter]
        ),
      };
      customFilter.push(filter);
    }

    this.filterTicketsTable({
      namespace: TablesNamespaces.MaintenancesTable,
      filter: customFilter.concat(this.customFilter),
    });
  }

  showAdvancedActions = false;

  devicesData: Device[] = [];
  laborersData: Laborer[] = [];
  maintenanceTypeData: Array<{ text: string; value: string }> =
    maintenanceTypes();

  deviceNameFilter = "";
  dateFilter = "";
  typeFilter = "";
  laborerFilter = "";

  currentMonthFilter = false;
  lastMonthFilter = false;

  get customFilter(): TableFilter<MaintenancesFilterEnum> {
    const buildFilter = (
      column: MaintenancesFilterEnum,
      value: [string, string]
    ) => ({
      filter: {
        column: column,
        operator: FilterOperatorEnum.Between,
        values: value,
      },
    });

    const customFilter: TableFilter<MaintenancesFilterEnum> = [
      {
        filter: {
          column: MaintenancesFilterEnum.State,
          operator: FilterOperatorEnum.Equal,
          values: [MaintenanceStateEnum.Done],
        },
      },
    ];

    if (this.currentMonthFilter) {
      customFilter.push(
        buildFilter(MaintenancesFilterEnum.Maintenancedate, [
          DateTime.local().startOf("month").toFormat("dd.MM.yyyy"),
          DateTime.local().endOf("month").toFormat("dd.MM.yyyy"),
        ])
      );
    }
    if (this.lastMonthFilter) {
      customFilter.push(
        buildFilter(MaintenancesFilterEnum.Maintenancedate, [
          DateTime.local()
            .minus({ month: 1 })
            .startOf("month")
            .toFormat("dd.MM.yyyy"),
          DateTime.local()
            .minus({ month: 1 })
            .endOf("month")
            .toFormat("dd.MM.yyyy"),
        ])
      );
    }
    return customFilter;
  }

  updateCurrentMonthFilter() {
    this.currentMonthFilter = !this.currentMonthFilter;
    this.filterTicketsTable({
      namespace: TablesNamespaces.MaintenancesTable,
      filter: this.customFilter,
    });
  }

  updateLastMonthFilter() {
    this.lastMonthFilter = !this.lastMonthFilter;
    this.filterTicketsTable({
      namespace: TablesNamespaces.MaintenancesTable,
      filter: this.customFilter,
    });
  }

  tableOptions: TableOptions<Maintenance, MaintenancesFilterEnum> = {
    defaultSortBy: ["device"],
    filterableColumns: [
      {
        enumValues: [],
        value: MaintenancesFilterEnum.Maintenancedate,
        text: "Datum",
        type: TableColumnDataType.DateTime,
      },
    ],
    defaultFilter: [
      {
        filter: {
          column: MaintenancesFilterEnum.State,
          operator: FilterOperatorEnum.Equal,
          values: [MaintenanceStateEnum.Done],
        },
      },
    ],
    headers: [
      {
        text: "Zařízení",
        sortable: true,
        align: "start",
        value: "device",
      },
      {
        text: "Datum",
        sortable: true,
        align: "start",
        value: "maintenanceDate",
      },
      {
        text: "Typ",
        sortable: true,
        align: "start",
        value: "type",
      },
      {
        text: "Zodp. osoba",
        sortable: true,
        align: "start",
        value: "responsiblePerson",
      },
      {
        text: "Cena",
        sortable: true,
        align: "start",
        value: "price",
      },
    ],
    namespace: TablesNamespaces.MaintenancesTable,
  };

  async updateItem(maintenance: Maintenance): Promise<void> {
    await this.$router.push({
      name: Routes.MaintenancesCompletedForm,
      params: { id: maintenance.id.toString() },
    });
  }

  async created() {
    const { data } = await apiClient.callGraphqlPrivate<
      {
        devices: {
          items: [Device];
        };
        laborers: {
          items: [Laborer];
        };
      },
      null
    >({
      ...api.maintenances.maintenanceAdvancedFilterData,
    });
    if (data) {
      this.devicesData = data?.devices.items;
      this.laborersData = data?.laborers.items;
    }
  }
}
