



















import { Component, Vue } from "vue-property-decorator";
import Modal from "../../commons/Modal.vue";
import { ValidationObserver } from "vee-validate";
import Button from "../../commons/inputsAndControls/Button.vue";
import TextField from "../../commons/inputsAndControls/TextField.vue";
import { api } from "../../../api";
import { EventBus } from "../../../enums";
import { eventBus } from "../../../utils/eventBus";
import {
  Device,
  DeviceFormFragment,
  UpdateDeviceMutation,
  UpdateDeviceMutationVariables,
} from "../../../types/gqlGeneratedPrivate";
import DeviceForm from "./DeviceForm.vue";
import { apiClient } from "../../../utils/apiClient";
import { Action } from "vuex-class";
import { TablesActions, TablesNamespaces } from "../../../store/modules/tables";
import { TableRefreshPayload } from "../../../types";

const emptyFormData: DeviceFormFragment = {
  name: "",
  note: "",
  number: "",
  laborerId: 0,
};

@Component({
  components: {
    Button,
    Modal,
    DeviceForm,
    TextField,
    ValidationObserver,
  },
})
export default class DeviceUpdateModal extends Vue {
  isOpen = false;
  isSending = false;

  deviceId = 0;

  formData: DeviceFormFragment = {
    ...emptyFormData,
  };

  @Action(TablesActions.Refresh, {
    namespace: TablesNamespaces.DevicesTable,
  })
  refreshTable!: (payload: TableRefreshPayload) => Promise<void>;

  created(): void {
    eventBus.$on(EventBus.DeviceUpdateModal, (payload: Device) => {
      this.formData = {
        name: payload.name,
        number: payload.number,
        note: payload.note,
        laborerId: payload.laborerId,
      };
      this.deviceId = payload.id;
      this.isOpen = true;
      this.initForm();
    });
  }

  async onSubmit(): Promise<void> {
    const valid = await (this.$refs.form as any).validate();
    if (valid) {
      await this.sendForm(this.formData);
    }
  }

  async sendForm(formData: DeviceFormFragment): Promise<void> {
    this.isSending = true;
    const result = await apiClient.callGraphqlPrivate<
      UpdateDeviceMutation,
      UpdateDeviceMutationVariables
    >({
      ...api.devices.updateDevice,
      variables: {
        id: this.deviceId,
        input: formData,
      },
    });
    if (result) {
      await this.refreshTable({
        namespace: TablesNamespaces.DevicesTable,
      });
      eventBus.$emit(EventBus.DeviceUpdatedEvent, !!result);
    }
    this.isOpen = false;
    this.isSending = false;
  }

  initForm(): void {
    this.isSending = false;
    this.$nextTick(() => {
      if (this.$refs.form) {
        (this.$refs.form as any).reset();
      }
    });
  }

  cancel(): void {
    this.isOpen = false;
    this.initForm();
  }
}
