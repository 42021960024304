
























import { Component, Prop, Vue } from "vue-property-decorator";
import AppLayout from "../../commons/layouts/AppLayout.vue";
import Table from "../../commons/tables/Table.vue";
import TableHeader from "../../commons/tables/TableHeader.vue";
import ActionsWrapper from "../../commons/tables/ActionsWrapper.vue";
import Button from "../../commons/inputsAndControls/Button.vue";
import RoundButton from "../../commons/inputsAndControls/RoundButton.vue";
import { TableOptions } from "../../../types";
import {
  FilterOperatorEnum,
  RegularMaintenance,
  RegularMaintenancesFilterEnum,
} from "../../../types/gqlGeneratedPrivate";
import { TablesNamespaces } from "../../../store/modules/tables";
import { EventBus } from "../../../enums/EventBus";
import { eventBus } from "../../../utils/eventBus";
import RegularMaintenanceDeleteModal from "./RegularMaintenanceDeleteModal.vue";
import RegularMaintenanceCreateModal from "./RegularMaintenanceCreateModal.vue";
import RegularMaintenanceUpdateModal from "./RegularMaintenanceUpdateModal.vue";

@Component({
  components: {
    AppLayout,
    Button,
    RoundButton,
    Table,
    TableHeader,
    ActionsWrapper,
    RegularMaintenanceDeleteModal,
    RegularMaintenanceCreateModal,
    RegularMaintenanceUpdateModal,
  },
})
export default class RegularMaintenancesTable extends Vue {
  private maintenanceName = "";

  @Prop({ type: Number, required: false })
  deviceId!: number;

  tableOptions: TableOptions<
    RegularMaintenance,
    RegularMaintenancesFilterEnum
  > | null = null;

  created() {
    this.tableOptions = {
      defaultFilter: [
        {
          filter: {
            column: RegularMaintenancesFilterEnum.DeviceId,
            operator: FilterOperatorEnum.Equal,
            values: [this.deviceId.toString()],
          },
        },
      ],
      headers: [
        {
          text: "Název",
          sortable: true,
          align: "start",
          value: "name",
        },
        {
          text: "Počet",
          sortable: true,
          align: "start",
          value: "number",
        },
        {
          text: "Frekvence",
          sortable: true,
          align: "start",
          value: "frequency",
        },
        {
          text: "",
          sortable: true,
          align: "start",
          value: "actions",
        },
      ],
      namespace: TablesNamespaces.RegularMaintenancesTable,
    };
  }

  deleteItem(regularMaintenance: RegularMaintenance): void {
    this.maintenanceName = regularMaintenance.name;

    eventBus.$emit(EventBus.RegularMaintenanceDeleteModal, regularMaintenance);
  }

  addItem(): void {
    eventBus.$emit(EventBus.RegularMaintenanceCreateModal);
  }

  updateItem(regularMaintenance: RegularMaintenance): void {
    eventBus.$emit(EventBus.RegularMaintenanceUpdateModal, regularMaintenance);
  }
}
