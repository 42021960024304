






















import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
import { Rules } from "../../../utils/veeValidate";

@Component({
  components: {
    ValidationProvider,
  },
})
export default class Textarea extends Vue {
  @Prop({ required: false, type: [Number, String], default: "" })
  private value!: number | string;

  @Prop({ required: false, type: String })
  private label?: string;

  @Prop({ required: true, type: String })
  private name!: string;

  @Prop({ required: false, type: [Object, String] })
  private rules?: Rules;

  @Prop({ required: false, type: String, default: "" })
  private hint!: string;

  @Prop({ required: false, type: String, default: "text" })
  private type!: "number" | "password" | "text";

  @Prop({ required: false, type: Boolean, default: false })
  private autofocus!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  private disabled!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  private hideDetails!: boolean;

  @Prop({ required: false, type: String })
  private prependInnerIcon?: string;

  @Prop({ required: false, type: Number, default: 4 })
  private rows!: number;

  @Prop({ required: false, type: [Boolean, Number], default: false })
  private counter!: boolean | number;
}
