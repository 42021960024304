var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TableHeader',{attrs:{"table-options":_vm.tableOptions,"title":"Uskutečněný servis"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"d-flex gap-2"},[_c('Button',{attrs:{"outlined":!_vm.lastMonthFilter,"color":"secondary"},on:{"click":_vm.updateLastMonthFilter}},[_vm._v("MINULÝ MĚSÍC")]),_c('Button',{attrs:{"outlined":!_vm.currentMonthFilter,"color":"secondary"},on:{"click":_vm.updateCurrentMonthFilter}},[_vm._v("AKTUÁLNÍ MĚSÍC")]),_c('Button',{attrs:{"outlined":!_vm.showAdvancedActions,"color":"secondary"},on:{"click":function($event){_vm.showAdvancedActions = !_vm.showAdvancedActions}}},[_vm._v("ROZŠÍŘENÝ FILTR")])],1)]},proxy:true},(_vm.showAdvancedActions)?{key:"advancedActions",fn:function(){return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('SelectBox',{attrs:{"clearable":"","label":"Název zařízení","item-text":"name","item-value":"id","items":_vm.devicesData,"name":"Device name"},model:{value:(_vm.deviceNameFilter),callback:function ($$v) {_vm.deviceNameFilter=$$v},expression:"deviceNameFilter"}})],1),_c('v-col',[_c('DateField',{attrs:{"clearable":"","label":"Datum","name":"Date filter"},model:{value:(_vm.dateFilter),callback:function ($$v) {_vm.dateFilter=$$v},expression:"dateFilter"}})],1),_c('v-col',[_c('SelectBox',{attrs:{"clearable":"","label":"Typ","items":_vm.maintenanceTypeData,"name":"Type"},model:{value:(_vm.typeFilter),callback:function ($$v) {_vm.typeFilter=$$v},expression:"typeFilter"}})],1),_c('v-col',[_c('SelectBox',{attrs:{"clearable":"","item-value":"id","name":"type","label":"Pověřená osoba","items":_vm.laborersData,"customData":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(item.firstname)+" "+_vm._s(item.surname)+" ")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstname)+" "+_vm._s(item.surname)+" ")]}}],null,false,1208040497),model:{value:(_vm.laborerFilter),callback:function ($$v) {_vm.laborerFilter=$$v},expression:"laborerFilter"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('Button',{attrs:{"color":"secondary"},on:{"click":_vm.prepareAdvancedFilter}},[_vm._v(" FILTROVAT ")])],1)],1)]},proxy:true}:null],null,true)}),_c('Table',{attrs:{"table-options":_vm.tableOptions},scopedSlots:_vm._u([{key:"device",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
          name: _vm.Routes.MaintenancesCompletedForm,
          params: {
            id: item.id.toString(),
          },
        }}},[_c('span',[_vm._v(_vm._s(item.device.name))])])]}},{key:"maintenanceDate",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocalDateTime")(item.maintenanceDate))+" ")]}},{key:"type",fn:function(ref){
        var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.textForMaintenanceType(value))+" ")]}},{key:"responsiblePerson",fn:function(ref){
        var value = ref.value;
return [_vm._v(" "+_vm._s(value.firstname)+" "+_vm._s(value.surname)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }