




import { Component, Vue } from "vue-property-decorator";
import AppLayout from "../components/commons/layouts/AppLayout.vue";
import TableHeader from "../components/commons/tables/TableHeader.vue";
import ActionsWrapper from "../components/commons/tables/ActionsWrapper.vue";
import RoundButton from "../components/commons/inputsAndControls/RoundButton.vue";
import Table from "../components/commons/tables/Table.vue";
import Button from "../components/commons/inputsAndControls/Button.vue";
import CompletedMaintenancesTable from "../components/app/maintenances/CompletedMaintenancesTable.vue";
import DeviceUpdateModal from "../components/app/devices/DeviceUpdateModal.vue";
import RegularMaintenancesTable from "../components/app/regularMaintenances/RegularMaintenancesTable.vue";

@Component({
  components: {
    RegularMaintenancesTable,
    DeviceUpdateModal,
    CompletedMaintenancesTable,
    Button,
    Table,
    RoundButton,
    ActionsWrapper,
    TableHeader,
    AppLayout,
  },
})
export default class CompletedMaintenancePage extends Vue {}
