























import Button from "../../commons/inputsAndControls/Button.vue";
import RegularMaintenanceForm from "./RegularMaintenanceForm.vue";
import Modal from "../../commons/Modal.vue";
import { Action } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import { EventBus } from "../../../enums";
import {
  RegularMaintenance,
  UpdateRegularMaintenanceInput,
} from "../../../types/gqlGeneratedPrivate";
import { TableRefreshPayload } from "../../../types";
import { TablesActions, TablesNamespaces } from "../../../store/modules/tables";
import { alerts } from "../../../utils";
import { api } from "../../../api";
import { apiClient } from "../../../utils/apiClient";
import { eventBus } from "../../../utils/eventBus";

@Component({
  components: {
    Button,
    Modal,
    RegularMaintenanceForm,
  },
})
export default class RegularMaintenanceUpdateModal extends Vue {
  isOpen = false;
  isSending = false;

  regularMaintenanceId = 0;

  formData: UpdateRegularMaintenanceInput | null = null;

  @Action(TablesActions.Refresh, {
    namespace: TablesNamespaces.RegularMaintenancesTable,
  })
  refreshTable!: (payload: TableRefreshPayload) => Promise<void>;

  created(): void {
    eventBus.$on(
      EventBus.RegularMaintenanceUpdateModal,
      (payload: RegularMaintenance) => {
        this.formData = {
          number: payload.number,
          frequency: payload.frequency,
          name: payload.name,
        };
        this.regularMaintenanceId = payload.id;
        this.isOpen = true;
        this.initForm();
      }
    );
  }

  async onSubmit(): Promise<void> {
    const valid = await (this.$refs.form as any).validate();
    if (valid && this.formData) {
      await this.sendForm(this.formData);
    }
  }

  async sendForm(formData: UpdateRegularMaintenanceInput): Promise<void> {
    this.isSending = true;
    const result = await apiClient.callGraphqlPrivate<
      RegularMaintenance,
      { id: number; input: UpdateRegularMaintenanceInput }
    >({
      ...api.regularMaintenances.updateRegularMaintenance,
      variables: {
        id: this.regularMaintenanceId,
        input: formData,
      },
    });
    if (result.data) {
      alerts.addSuccessAlert("UPDATE_REGULAR_MAINTENANCE", "Uloženo");
      await this.refreshTable({
        namespace: TablesNamespaces.RegularMaintenancesTable,
      });
    }
    this.isOpen = false;
    this.isSending = false;
  }

  initForm(): void {
    this.isSending = false;
    this.$nextTick(() => {
      if (this.$refs.form) {
        (this.$refs.form as any).reset();
      }
    });
  }

  cancel(): void {
    this.isOpen = false;
    this.initForm();
  }
}
