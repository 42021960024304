


























import Button from "../../commons/inputsAndControls/Button.vue";
import TextField from "../../commons/inputsAndControls/TextField.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  RegularMaintenanceFrequencyEnum,
  CreateRegularMaintenanceInput,
} from "../../../types/gqlGeneratedPrivate";
import { ValidationObserver } from "vee-validate";
import SelectBox from "../../commons/inputsAndControls/SelectBox.vue";
import { i18n } from "../../../utils";

@Component({
  components: {
    SelectBox,
    Button,
    TextField,
    ValidationObserver,
  },
})
export default class RegularMaintenanceForm extends Vue {
  @Prop({ type: Object, required: true })
  value!: CreateRegularMaintenanceInput;

  @Prop({ type: Boolean, required: false, default: false })
  showIdField!: boolean;

  i18n = i18n;

  frequency: [
    {
      text: string;
      value: RegularMaintenanceFrequencyEnum;
    },
    {
      text: string;
      value: RegularMaintenanceFrequencyEnum;
    },
    {
      text: string;
      value: RegularMaintenanceFrequencyEnum;
    },
    {
      text: string;
      value: RegularMaintenanceFrequencyEnum;
    }
  ] = [
    {
      text: this.$t("frequency.daily") as string,
      value: RegularMaintenanceFrequencyEnum.Daily,
    },
    {
      text: this.$t("frequency.weekly") as string,
      value: RegularMaintenanceFrequencyEnum.Weekly,
    },
    {
      text: this.$t("frequency.monthly") as string,
      value: RegularMaintenanceFrequencyEnum.Monthly,
    },
    {
      text: this.$t("frequency.yearly") as string,
      value: RegularMaintenanceFrequencyEnum.Yearly,
    },
  ];

  async validate(): Promise<boolean> {
    return (this.$refs.observer as any).validate();
  }

  reset(): void {
    (this.$refs.observer as any).reset();
  }
}
