























import { Component, Prop, Vue } from "vue-property-decorator";
import Modal from "../../commons/Modal.vue";
import { ValidationObserver } from "vee-validate";
import Button from "../../commons/inputsAndControls/Button.vue";
import TextField from "../../commons/inputsAndControls/TextField.vue";
import SelectBox from "../../commons/inputsAndControls/SelectBox.vue";
import {
  DeviceFormFragment,
  LaborerListQuery,
  LaborerListQueryVariables,
} from "../../../types/gqlGeneratedPrivate";
import Textarea from "../../commons/inputsAndControls/Textarea.vue";
import { api } from "../../../api";
import { apiClient } from "../../../utils";

@Component({
  components: {
    Textarea,
    Modal,
    ValidationObserver,
    Button,
    TextField,
    SelectBox,
  },
})
export default class DeviceForm extends Vue {
  laborers: Array<{ text: string; value: number }> = [];

  @Prop({ type: Object, required: true })
  value!: DeviceFormFragment;

  async created() {
    const { data } = await apiClient.callGraphqlPrivate<
      LaborerListQuery,
      LaborerListQueryVariables
    >({
      ...api.laborers.laborerList,
    });
    if (data?.laborers) {
      this.laborers = data?.laborers.items.map((item) => ({
        value: item.id,
        text: `${item.firstname} ${item.surname}`,
      }));
    }
  }

  async validate(): Promise<boolean> {
    return (this.$refs.observer as any).validate();
  }

  reset(): void {
    (this.$refs.observer as any).reset();
  }
}
